import React from 'react'
import PropTypes from 'prop-types'
import Render from 'lyjs/es/components/Render'
import {Form} from 'antd'

const {
  Item: FormItem
} = Form

export function defaultHelp(meta) {
  const {
    error,
    warning,
    touched
  } = meta

  return touched ? warning || error : undefined
}

export function defaultValidateStatus(meta) {
  const {
    warning,
    touched,
    invalid
  } = meta

  if (warning) {
    return 'warning'
  }

  if (touched) {
    return invalid ? 'error' : 'success'
  }

  return undefined
}

function FieldItem(props) {
  const {
    component,
    render,
    meta,
    input,
    help: helpProp = defaultHelp,
    validateStatus: validateStatusProp = defaultValidateStatus,
    children,
    ...ownProps
  } = props

  const help = typeof helpProp === 'function'
    ? helpProp(meta, input, props)
    : helpProp
  const validateStatus = typeof validateStatusProp === 'function'
    ? validateStatusProp(meta, input, props)
    : validateStatusProp

  return (
    <FormItem
      {...ownProps}
      help={help}
      validateStatus={validateStatus}
    >
      {
        Render({
          component,
          render,
          meta,
          input,
          children
        })
      }
    </FormItem>
  )
}

if (process.env.NODE_ENV !== 'production') {
  FieldItem.propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    render: PropTypes.func,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    help: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    validateStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    children: PropTypes.any
  }
}

export default FieldItem
