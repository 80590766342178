function createValidate(fn) {
  return (message) => {
    return (...args) => {
      return fn(...args)
        ? undefined
        : message
    }
  }
}

export default createValidate
