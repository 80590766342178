import React from 'react'
import compose from 'lyjs/es/fp/compose'
import {connect} from 'react-redux'
import {withRouter, Redirect} from 'react-router-dom'
import withProps, {defaultMergeProps} from 'lyjs/es/components/withProps'
import withQuery from 'lyjs/es/components/withQuery'
import Trigger from 'lyjs/es/components/Trigger'

import {authorization, unauthorization, login, getCode, verify} from 'services/store/profile/effects'
import selector from './selector'

function getToken(props) {
  const {
    match: {
      query: {
        auth_token
      }
    },
    token
  } = props

  return auth_token || token
}

const mapStateToProps = selector
const mapDispatchToProps = (dispatch) => {
  return {
    authorization(token) {
      dispatch(authorization(token))
    },
    login(mobile, code, callback) {
      dispatch(login(mobile, code, callback))
    },
    logout() {
      dispatch(unauthorization())
    },
    getCode(mobile, callback) {
      dispatch(getCode(mobile, callback))
    },
    verify(mobile, code, callback) {
      dispatch(verify(mobile, code, callback))
    }
  }
}

function mergeProps(presetProps, ownProps) {
  const {
    history: {
      createLocation
    },
    location: {
      pathname
    },
    match: {
      query
    },
    authorization: action,
  } = ownProps

  return {
    ...defaultMergeProps(presetProps, ownProps),
    action,
    createRestoreRedirect(defaultNext = '/') {
      const {
        _next: next = defaultNext,
        ...otherQuery
      } = query

      const location = createLocation({
        pathname: next,
        query: otherQuery
      })

      return (
        <Redirect
          replace
          to={location}
        />
      )
    },
    createNextRedirect(to = '/login') {
      const location = createLocation({
        pathname: to,
        query: {
          ...query,
          _next: pathname
        }
      })

      return (
        <Redirect
          replace
          to={location}
        />
      )
    }
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withQuery(),
  withProps({
    mergeProps,
    displayName: 'AuthorizationComposer',
    params: getToken
  })
)(Trigger)
