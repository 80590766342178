import React from 'react'
import PropTypes from 'prop-types'
import cs from 'lyjs/es/utils/classes'

import styles from './styles.module.css'

export const Themes = {
  LIGHT: 'light',
  DARK: 'dark'
}

export const Types = {
  DISPLAY4: 'display4',
  DISPLAY3: 'display3',
  DISPLAY2: 'display2',
  DISPLAY1: 'display1',
  HEADLINE: 'headline',
  TITLE: 'title',
  SUBHEADING: 'subheading',
  BODY2: 'body2',
  BODY1: 'body1',
  CAPTION: 'caption'
}

export const Aligns = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify'
}

export const Transforms = {
  LOWERCASE: 'lowercase',
  UPPERCASE: 'uppercase',
  CAPITALIZE: 'capitalize'
}

function Text(props) {
  const {
    component: Component = 'span',
    theme = Themes.LIGHT,
    type,
    disabled,
    inherit,
    nowrap,
    pre,
    truncate,
    align,
    transform,
    className: classNameProp,
    ...otherProps
  } = props

  const className = cs(
    classNameProp,
    styles.text,
    {
      [styles.inherit]: inherit,
      [styles.nowrap]: nowrap,
      [styles.pre]: pre,
      [styles.truncate]: truncate,
      [styles[`align-${align}`]]: align,
      [styles[`transform-${transform}`]]: transform,
      [styles[`${type}-${theme}`]]: type,
      [styles[`disabled-${theme}`]]: disabled
    }
  )

  return (
    <Component
      {...otherProps}
      className={className}
    />
  )
}

if (process.env.NODE_ENV !== 'production') {
  Text.propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    theme: PropTypes.oneOf(Object.values(Themes)),
    type: PropTypes.oneOf(Object.values(Types)),
    disabled: PropTypes.bool,
    inherit: PropTypes.bool,
    nowrap: PropTypes.bool,
    pre: PropTypes.bool,
    truncate: PropTypes.bool,
    align: PropTypes.oneOf(Object.values(Aligns)),
    transform: PropTypes.oneOf(Object.values(Transforms))
  }
}

export default Text
