import Review from 'services/request/Review'

class Item extends Review {
  constructor() {
    super({
      entry: 'item'
    })
  }

  listReview(reviewed, keyword) {
    return this
      .all('list')
      .get({
        params: {
          reviewed,
          keyword: keyword ? keyword : undefined
        }
      })
      .then((data) => {
        const {
          items
        } = data

        return items
      })
  }

  fetchReview(zone_item_id) {
    return this
      .one(zone_item_id, 'review')
      .get()
  }

  reviewItem(data) {
    return this
      .custom('review')
      .put(data)
  }
}

export default new Item()
