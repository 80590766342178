import {createStructuredSelector} from 'reselect'

import {
  mobileSelector,
  tokenSelector,
  authorizedSelector,
  pendingSelector
} from 'services/store/profile/selectors'

const selector = createStructuredSelector({
  mobile: mobileSelector,
  token: tokenSelector,
  authorized: authorizedSelector,
  pending: pendingSelector
})

export default selector
